import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Banner from "../../components/HomePage/Banner/Banner";
import SubBanner from "../../components/HomePage/SubBanner/SubBanner";
import Why from "../../components/HomePage/Why/Why";
import Approach from "../../components/HomePage/Approach/Approach";
import Slider from "../../components/HomePage/Slider/Slider";
import If from "../../components/HomePage/If/If";
import Brokers from "../../components/HomePage/Brokers/Brokers";
import Bottom from "../../components/HomePage/Bottom/Bottom";


const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <HelmetProvider>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <SubBanner />
        <Why />
        <Approach />
        <Slider />
        <If />
        <Brokers />
        {/*<Bottom />*/}
      </div>
    </HelmetProvider>
  );
};

export default HomePage;
