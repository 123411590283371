import React from "react";
import classes from "./Button.module.scss";

import $ from "jquery";
const Button = ({ children, login, dark }) => {
  function handleClick() {
    setTimeout(() => {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#mainForm").offset().top,
        },
        2000
      );
    }, 200);
  }
  return (
    <>
      {login ? (
        <div
          className={`${classes.button} ${dark ? classes.buttonDark : ""}`}

        >
          <span>{children}</span>
        </div>
      ) : (
        <div
          className={`${classes.button}`}
          onClick={() => {
            handleClick();
          }}
        >
          <span>{children}</span>
        </div>
      )}
    </>
  );
};

export default Button;
