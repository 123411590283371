import HomePage from '../pages/HomePage/HomePage';

export const routes = [
    { path: '/', element: HomePage },
    { path: '/:lang/', element: HomePage },
    { path: '*', element: HomePage },
];

export const headerRoutes = [
];
